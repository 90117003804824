<script setup>
import stores from "@/Stores/stores.js";
import {storeToRefs} from "pinia";
import {InputToggle} from "@ryokutech/brainkit_frontend";
import LocalesDropdown from "@/Components/Locales/LocalesDropdown.vue";

import {computed, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";

import ButtonDefault from "@/Components/Buttons/ButtonDefault.vue";
import TitleDefault from "@/Components/Titles/TitleDefault.vue";
import DividerSmall from "@/Components/Dividers/DividerSmall.vue";
import IconChevronDoubleRight from "@/Components/Icons/IconChevronDoubleRight.vue";
import TermsModal from "@/Components/Terms/TermsModal.vue";
import LayoutNav from "@/Layouts/LayoutNav.vue";
import Translation from "@/Components/Translation/Translation.vue";
import Image from "@/Components/Images/Image.vue";

const {configStore, objectStore} = stores();
const {activeObject, activeLocale, languages, isOpenTermsModal} = storeToRefs(configStore);
const {objectData} = storeToRefs(objectStore);


const hasAgreedTerms = ref(true);

onMounted(() => {
    if (activeLocale.value === null) {
        router.get(route('landing.index'));
    }
});

const capitalizedActiveObject = computed(() => {
    if (activeObject.value && activeObject.value.length > 0) {
        return activeObject.value.charAt(0).toUpperCase() + activeObject.value.slice(1);
    }
    return '';
});

</script>

<template>
    <LayoutNav
        :logo-nav="true"
        class="mb-20">

        <Image filename="cover_image_landing.png"/>

        <DividerSmall/>

        <TitleDefault color="text-black" class="text-center">
            <Translation tkey="ui.landing.quote" :replacements="{name: capitalizedActiveObject }"/>
        </TitleDefault>

        <DividerSmall/>

        <TitleDefault class="mb-4">
            <Translation tkey="ui.word.language"/>
        </TitleDefault>

        <LocalesDropdown class="mb-4"/>

        <DividerSmall/>

        <TitleDefault
            @click="isOpenTermsModal = true"
            class="mb-4 underline">
            <Translation tkey="ui.word.terms"/>
        </TitleDefault>


        <div class="flex items-center mb-4 mt-6">
            <InputToggle
                size="medium"
                backgroundColorClass="!bg-brainkitSuccess"
                v-model="hasAgreedTerms"
            />
            <p class="break-words ml-2 text-brainkitGrayscale3" @click="isOpenTermsModal = true">
                <Translation tkey="ui.word.agree"/>
            </p>
        </div>


        <ButtonDefault
            :icon-right="IconChevronDoubleRight"
            class="mt-14 mb-2"
            :disabled="!hasAgreedTerms"
            @click="router.get(route('content.index'))"
        >
            {{ $t('ui.word.start') }}
        </ButtonDefault>

        <TermsModal/>
    </LayoutNav>
</template>
