<script setup>
import {Modal} from "@ryokutech/brainkit_frontend";
import stores from "@/Stores/stores.js";
import {storeToRefs} from "pinia";
import {computed} from "vue";

const {configStore } = stores();
const {isOpenTermsModal, activeLocale} = storeToRefs(configStore);

//check if active locale is dutch or another language computed
const isDutch = computed(() => {
    return activeLocale.value === "nl";
});


</script>


<template>


    <Modal
        @close="isOpenTermsModal = false"
        :show="isOpenTermsModal"
    >

        <div class="container mx-auto py-10">

            <!--    DUTCH      -->
            <template v-if="isDutch">

                <h1 class="text-3xl font-bold text-center my-6">Navi Noa - Algemene Voorwaarden</h1>

                <div class="bg-white p-6 rounded-lg shadow mb-6">
                    <h2 class="text-2xl font-bold mb-4">Algemene Voorwaarden voor Navi Noa</h2>
                    <p class="mb-2"><strong>Laatst bijgewerkt:</strong> 29 oktober 2023</p>
                    <p>Lees deze Algemene Voorwaarden zorgvuldig door voordat u Navi Noa gebruikt. Door gebruik te maken
                        van de Navi Noa website
                        en applicatie (hierna te noemen "Navi Noa"), gaat u akkoord met de volgende voorwaarden:</p>
                    <h2>1. Gebruiksvoorwaarden</h2>
                    <p>a. U stemt ermee in Navi Noa op eigen risico te gebruiken. De door Navi Noa verstrekte informatie
                        wordt gegenereerd door
                        generatieve AI en kan onjuist of onvolledig zijn. Wij zijn niet verantwoordelijk voor eventuele
                        schade of verlies
                        als gevolg van het gebruik van deze informatie.</p>
                    <p>b. U stemt ermee in Navi Noa uitsluitend te gebruiken voor entertainment, educatieve en
                        informatieve doeleinden en niet
                        de service te misbruiken door deel te nemen aan illegale of onethische activiteiten.</p>
                    <h2>2. Intellectueel Eigendom </h2>
                    <p>a. Alle inhoud en materialen die beschikbaar zijn via Navi Noa, inclusief maar niet beperkt tot
                        tekst, afbeeldingen,
                        software, logo's en andere intellectuele eigendomsrechten, zijn eigendom van Navi Noa, onze
                        partners
                        of onze licentiegevers.</p>
                    <p>b. U mag de inhoud van Navi Noa niet kopiëren, verspreiden, wijzigen, reverse engineeren of
                        anderszins gebruiken zonder
                        onze uitdrukkelijke schriftelijke toestemming.</p>
                    <h2>3. Privacy en Gegevensverwerking </h2>
                    <p>a. Wij verzamelen en verwerken persoonsgegevens in overeenstemming met ons Privacybeleid. Door
                        Navi Noa te gebruiken, stemt u
                        in met deze gegevensverwerking.</p>
                    <h2>4. Aansprakelijkheid</h2>
                    <p>a. Wij streven ernaar om Navi Noa zo nauwkeurig en betrouwbaar mogelijk te houden, maar we kunnen
                        de
                        nauwkeurigheid of beschikbaarheid van de informatie niet garanderen.</p>
                    <p>b. In geen geval zijn wij aansprakelijk voor enige directe, indirecte, incidentele, speciale,
                        gevolgschade of
                        strafschade die voortvloeit uit het gebruik of de onmogelijkheid tot gebruik van Navi Noa.</p>
                    <h2>5. Wijzigingen en Beëindiging</h2>
                    <p>a. Wij behouden ons het recht voor om Navi Noa op elk moment zonder kennisgeving te wijzigen, op
                        te schorten of te beëindigen.</p>
                    <h2>6. Toepasselijk recht </h2>
                    <p>a. Deze Algemene Voorwaarden worden beheerst door de wetten van Nederland. Eventuele geschillen
                        met betrekking tot
                        deze algemene voorwaarden worden voorgelegd aan de bevoegde rechtbanken in Nederland.</p>
                    <p>b. Door Navi Noa te gebruiken, gaat u akkoord met deze Algemene Voorwaarden. Als u niet akkoord
                        gaat met deze voorwaarden,
                        gebruik Navi Noa dan niet.
                        <p></p>Neem contact met ons op als u vragen heeft over deze voorwaarden of over het gebruik van
                        Navi Noa.
                        U kunt contact met ons opnemen via info@navinoa.nl
                    </p>
                </div>


                <div class="bg-white p-6 rounded-lg shadow mb-6">
                    <h2 class="text-2xl font-bold mb-4">Cookieverklaring voor Navi Noa</h2>
                    <p class="mb-2"><strong>Laatst bijgewerkt:</strong> 29 oktober 2023</p>
                    <p>Deze cookieverklaring is van toepassing op de website en applicatie van Navi Noa (hierna te
                        noemen "Navi Noa"), geëxploiteerd door Ryoku.ai, gevestigd te Wilhelminapark 1 Venlo. Deze
                        verklaring legt uit wat
                        cookies zijn, hoe we ze gebruiken en welke controle u heeft over het gebruik van cookies tijdens
                        uw bezoek aan
                        Navi Noa.</p>
                    <h2>1. Wat zijn Cookies?</h2>
                    <p>Cookies zijn kleine tekstbestanden die worden opgeslagen op uw apparaat (bijv. computer,
                        smartphone of tablet)
                        wanneer u onze website of app bezoekt. Ze worden vaak gebruikt om informatie over u en uw
                        apparaat op te slaan voor verschillende doeleinden.</p>
                    <h2>2. Soorten Cookies die Wij Gebruiken </h2>
                    <p>a. Strikt Noodzakelijke Cookies: Deze cookies zijn essentieel voor de werking van Navi Noa. Ze
                        stellen u in staat
                        om door de website te navigeren en de functies ervan te gebruiken.</p>
                    <p>b. Prestatiecookies: Deze cookies verzamelen informatie over hoe bezoekers onze website
                        gebruiken, zoals
                        welke pagina's ze het vaakst bezoeken en of ze foutmeldingen krijgen. Deze gegevens helpen ons
                        de prestaties van Navi Noa te verbeteren.</p>
                    <p>c. Functionele cookies: Deze cookies worden gebruikt om uw keuzes en voorkeuren te onthouden,
                        zodat we een
                        meer gepersonaliseerde ervaring kunnen bieden.</p>
                    <p>d. Analytische cookies: We gebruiken analytische cookies van derden, zoals Google Analytics, om
                        informatie te verzamelen over het gebruik van onze website. Deze informatie helpt ons de website
                        te analyseren en te verbeteren.</p>
                    <h2>3. Beheer van Cookies</h2>
                    <p>a. U kunt het gebruik van cookies op elk moment beheren en aanpassen via uw browserinstellingen.
                        Raadpleeg de Help-functie van uw browser voor instructies over het uitschakelen, verwijderen of
                        blokkeren van
                        cookies.</p>
                    <p>b. Houd er rekening mee dat het uitschakelen van cookies bepaalde functies van Navi Noa kan
                        beïnvloeden en dat sommige delen van de
                        website mogelijk niet correct functioneren.</p>
                    <h2>4. Bewaarperiode van Cookies</h2>
                    <p>Cookies kunnen verschillende bewaarperioden hebben. Sommige cookies worden verwijderd zodra u uw
                        browser sluit (sessiecookies), terwijl andere voor een langere periode op uw apparaat worden
                        opgeslagen.</p>
                    <h2>5. Wijzigingen in onze Cookieverklaring</h2>
                    <p>We kunnen deze Cookieverklaring van tijd tot tijd bijwerken om wijzigingen in het gebruik van
                        cookies op Navi
                        Noa weer te geven. Eventuele wijzigingen worden hier gepubliceerd en de datum van de laatste
                        update wordt bovenaan de verklaring vermeld.</p>
                    <h2>6. Contactinformatie</h2>
                    <p>Als u vragen heeft over onze Cookieverklaring of cookies in het algemeen, neem dan contact met
                        ons op via
                        info@navinoa.nl.</p>
                </div>

                <div class="bg-white p-6 rounded-lg shadow">
                    <h2 class="text-2xl font-bold mb-4">Privacyverklaring van Navi Noa</h2>
                    <p class="mb-2"><strong>Laatst bijgewerkt:</strong> 29 oktober 2023</p>
                    <p>Deze privacyverklaring is van toepassing op de website en applicatie van Navi Noa (hierna te
                        noemen "Navi Noa"), geëxploiteerd door Ryoku.ai, gevestigd op Wilhelminapark 1 Venlo. In deze
                        verklaring leggen wij uit welke persoonsgegevens wij verzamelen, hoe wij deze gebruiken, delen
                        en beschermen, en welke rechten u heeft met betrekking tot uw persoonsgegevens.</p>
                    <h2>1. Verzamelde Gegevens</h2>
                    <p>a. Wij verzamelen beperkte persoonsgegevens wanneer u Navi Noa gebruikt, waaronder:</p>
                    <ul>
                        <li>Gegevens die u verstrekt bij registratie of bij het stellen van vragen via de applicatie.
                        </li>
                        <li>Informatie over uw interacties met Navi Noa, zoals de vragen die u stelt.</li>
                        <li>Apparaatinformatie, inclusief IP-adressen en apparaatidentifiers.</li>
                    </ul>
                    <h2>2. Gebruik van Informatie</h2>
                    <p>a. Wij gebruiken de verzamelde gegevens voor de volgende doeleinden:</p>
                    <ul>
                        <li>Om gebruikersvragen te beantwoorden en de functionaliteit van Navi Noa te leveren.</li>
                        <li>Voor interne analytische doeleinden om de prestaties van Navi Noa te verbeteren.</li>
                        <li>Om uw account en instellingen te beheren</li>
                    </ul>
                    <h2>3. Delen van Gegevens</h2>
                    <p>a. Wij delen uw persoonlijke gegevens niet met derden, behalve in de volgende gevallen:</p>
                    <ul>
                        <li>Met uw uitdrukkelijke toestemming.</li>
                        <li>Wanneer dit wettelijk vereist is of om te voldoen aan juridische verzoeken.</li>
                        <li>Met derden die diensten verlenen in verband met Navi Noa, zoals hostingproviders en
                            analytische dienstverleners.
                        </li>
                    </ul>
                    <h2>4. Beveiliging van Gegevens</h2>
                    <p>a. Wij nemen redelijke maatregelen om uw persoonlijke informatie te beschermen tegen
                        ongeautoriseerde toegang, openbaarmaking of wijziging. Wij houden uw gegevens veilig op onze
                        servers.</p>
                    <h2>5. Bewaartermijn van Gegevens</h2>
                    <p>a. Wij bewaren uw persoonsgegevens alleen zolang als nodig is voor de doeleinden waarvoor deze
                        zijn verzameld, tenzij anders vereist door de wet.</p>
                    <h2>6. Uw rechten</h2>
                    <p>a. U heeft bepaalde rechten met betrekking tot uw persoonsgegevens, waaronder het recht om:</p>
                    <ul>
                        <li>Uw gegevens in te zien, te corrigeren of te verwijderen.</li>
                        <li>Bezwaar te maken tegen de verwerking van uw gegevens.</li>
                        <li>Uw toestemming in te trekken als de verwerking gebaseerd is op toestemming.</li>
                    </ul>
                </div>

            </template>


            <!--     LANGUAGES OTHER THEN DUTCH       -->
            <template v-if="!isDutch">


                <h1 class="text-3xl font-bold text-center my-6">Navi Noa - Terms & Conditions</h1>

                <div class="bg-white p-6 rounded-lg shadow mb-6">
                    <h2 class="text-2xl font-bold mb-4">Terms & Conditions for Navi Noa</h2>
                    <p class="mb-2"><strong>Last updated:</strong> 29 October 2023</p>
                    <p>Please read these Terms and Conditions carefully before using Navi Noa. By using the Navi Noa
                        website
                        and application (hereinafter referred to as "Navi Noa"), you agree to the following terms:</p>
                    <h2>1. Terms of Use</h2>
                    <p>a. You agree to use Navi Noa at your own risk. The information provided by Navi Noa is generated
                        by
                        generative AI and may be incorrect or incomplete. We are not responsible for any damage or loss
                        arising from the use of this information.</p>
                    <p>b. You agree to use Navi Noa solely for entertainment, educational and informational purposes and
                        not
                        to abuse the service by engaging in illegal or unethical activities.</p>
                    <h2>2. Intellectual Property </h2>
                    <p>a. All content and materials available through Navi Noa, including but not limited to text,
                        images,
                        software, logos, and other intellectual property rights, are the property of Navi Noa, our
                        partners
                        or our licensors. </p>
                    <p>b. You may not copy, distribute, modify, reverse engineer or otherwise use the content of Navi
                        Noa
                        without our express written permission.</p>
                    <h2>3. Privacy and Data Processing </h2>
                    <p>a. We collect and process personal data in accordance with our Privacy Policy. By using Navi Noa,
                        you
                        consent to this data processing.</p>
                    <h2>4. Liability</h2>
                    <p>a. We strive to keep Navi Noa as accurate and reliable as possible, but we cannot guarantee the
                        accuracy or availability of the information.</p>
                    <p>b. In no event shall we be liable for any direct, indirect, incidental, special, consequential,
                        or
                        punitive damages arising out of the use or inability to use Navi Noa.</p>
                    <h2>5. Modifications and Termination</h2>
                    <p>a. We reserve the right to modify, suspend or terminate Navi Noa at any time without notice.</p>
                    <h2>6. Applicable law </h2>
                    <p>a. These Terms and Conditions are governed by the laws of the Netherlands. Any disputes relating
                        to
                        these terms and conditions shall be submitted to the competent courts in the Netherlands.</p>
                    <p>b. By using Navi Noa, you agree to these Terms and Conditions. If you do not agree to these
                        terms,
                        please do not use Navi Noa.
                        <p></p>Please contact us if you have any questions about these terms or about the use of Navi
                        Noa.
                        You can contact us at info@navinoa.nl
                    </p>
                </div>

                <div class="bg-white p-6 rounded-lg shadow mb-6">
                    <h2 class="text-2xl font-bold mb-4">Cookie Statement for Navi Noa</h2>
                    <p class="mb-2"><strong>Last updated:</strong> 29 October 2023</p>
                    <p>This cookie statement applies to the website and application of Navi Noa (hereinafter referred to
                        as
                        "Navi Noa"), operated by Ryoku.ai, located at Wilhelminapark 1 Venlo. This statement explains
                        what
                        cookies are, how we use them and what control you have over the use of cookies during your visit
                        to
                        Navi Noa.</p>
                    <h2>1. What are Cookies?</h2>
                    <p>Cookies are small text files that are stored on your device (e.g. computer, smartphone or tablet)
                        when you visit our website or app. They are often used to store information about you and your
                        device for a variety of purposes.</p>
                    <h2>2. Types of Cookies We Use </h2>
                    <p>a. Strictly Necessary Cookies: These cookies are essential for the operation of Navi Noa. They
                        allow
                        you to navigate the website and use its features.</p>
                    <p>b. Performance cookies: These cookies collect information about how visitors use our website,
                        such as
                        which pages they visit most often and if they get error messages. This data helps us to improve
                        the
                        performance of Navi Noa.</p>
                    <p>c. Functional cookies: These cookies are used to remember your choices and preferences so that we
                        can
                        provide a more personalized experience.</p>
                    <p>d. Analytical cookies: We use third-party analytical cookies, such as Google Analytics, to
                        collect
                        information about the use of our website. This information helps us to analyze and improve the
                        website.</p>
                    <h2>3. Management of Cookies</h2>
                    <p>a. You can manage and adjust the use of cookies at any time through your browser settings. Please
                        refer to your browser's Help function for instructions on how to disable, delete or block
                        cookies.</p>
                    <p>b. Please note that disabling cookies may affect certain features of Navi Noa and some parts of
                        the
                        website may not function properly.</p>
                    <h2>4. Cookie retention period</h2>
                    <p>Cookies can have different retention periods. Some cookies are deleted as soon as you close your
                        browser (session cookies), while others are stored on your device for a longer period of
                        time.</p>
                    <h2>5. Changes to our Cookie Statement</h2>
                    <p>We may update this Cookie Notice from time to time to reflect changes in the use of cookies on
                        Navi
                        Noa. Any changes will be published here, and the date of the last update will be listed at the
                        top
                        of the statement.</p>
                    <h2>6. Contact Information</h2>
                    <p>If you have any questions about our Cookie Statement or cookies in general, please contact us at
                        info@navinoa.nl.</p>
                    <!-- Continue with the rest of the content for Cookie Statement -->
                </div>

                <div class="bg-white p-6 rounded-lg shadow">
                    <h2 class="text-2xl font-bold mb-4">Navi Noa Privacy Statement</h2>
                    <p class="mb-2"><strong>Last updated:</strong> 29 October 2023</p>
                    <p>This privacy statement applies to the website and application of Navi Noa (hereinafter referred
                        to as
                        "Navi Noa"), operated by Ryoku.ai, located at Wilhelminapark 1 Venlo. In this statement, we
                        explain
                        what personal data we collect, how we use, share and protect it, and what rights you have in
                        relation to your personal data.</p>
                    <h2>1. Data Collected</h2>
                    <p>a. We collect limited personal data when you use Navi Noa, including:</p>
                    <ul>
                        <li>Data that you provide when registering or when asking questions via the application.</li>
                        <li>Information about your interactions with Navi Noa, such as the questions you ask.</li>
                        <li>Device information, including IP addresses and device identifiers.</li>
                    </ul>
                    <h2>2. Use of Information</h2>
                    <p>a. We use the collected data for the following purposes:</p>
                    <ul>
                        <li>To answer user questions and deliver the functionality of Navi Noa.</li>
                        <li>For internal analytical purposes to improve the performance of Navi Noa.</li>
                        <li>To manage your account and settings</li>
                    </ul>
                    <h2>3. Data Sharing</h2>
                    <p>a. We do not share your personal data with third parties, except in the following cases:</p>
                    <ul>
                        <li>With your explicit consent.</li>
                        <li>When required by law or to comply with legal requests.</li>
                        <li>With third parties who provide services in connection with Navi Noa, such as hosting
                            providers
                            and analytical service providers.
                        </li>
                    </ul>
                    <h2>4. Security of Data</h2>
                    <p>a. We take reasonable steps to protect your personal information from unauthorized access,
                        disclosure, or alteration. We keep your data safe on our servers.</p>
                    <h2>5. Data Retention Period</h2>
                    <p>a. We will only retain your personal data for as long as necessary for the purposes for which it
                        was
                        collected, unless otherwise required by law.</p>
                    <h2>6. Your rights</h2>
                    <p>a. You have certain rights in relation to your personal data, including the right to:</p>
                    <ul>
                        <li>View, correct or delete your data.</li>
                        <li>Object to the processing of your data.</li>
                        <li>Withdraw your consent if processing is based on consent.</li>
                    </ul>
                </div>

            </template>

        </div>


    </Modal>


</template>
